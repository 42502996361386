// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CopyIcon} from 'assets/svg/3.0/Copy.svg';
import classNames from 'classnames';
import {ScriptPreviewer} from 'components/ScriptPreviewer';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {copy} from 'lib/copy';
import {formatAspectRatio} from 'lib/ratio';
import {getTitleOrDefault, trimNewline} from 'modules/project/utils';
import {BilingualStoryClosedScene} from 'modules/scene/types';
import {getScriptCopyContent} from 'modules/scene/utils';
import {ReactEventHandler, useCallback, useMemo, useState} from 'react';
import {isRtl} from 'utils/is-rtl';

import {PreviewBox} from '../PreviewBox';
import styles from './ResultPreviewer.module.scss';
import {Props} from './ResultPreviewer.types';

export function ResultPreviewer({
  title,
  detailWidth,
  description,
  size,
  hashtags = [],
  video,
  prompt,
  language,
  nativeLanguage,
  loading = false,

  scenes,
}: Props) {
  const {showNotification} = useNotificationContext();
  const [duration, setDuration] = useState<number | null>(null);

  const hashTagStr = useMemo(
    () => hashtags.map(tag => `#${tag}`).join(' '),
    [hashtags]
  );

  const onVideoLoaded = useCallback<ReactEventHandler<HTMLVideoElement>>(e => {
    const element = e.currentTarget;
    setDuration(element.duration);
  }, []);

  const onCopy = useCallback(
    async (text: string) => {
      await copy(text);
      showNotification({message: 'Copied to clipboard', type: 'SUCCESS'});
    },
    [showNotification]
  );

  const {getLanguages, getNativeLanguages} = useResourceManager();
  const languages = getLanguages();

  const scenesNumber = (scenes && scenes.length) || 0;
  const scripts = scenes.map(scene => {
    if ('speaker' in scene) {
      return `${(scene.speaker && scene.speaker + ': ') || ''}${
        scene.subtitle
      }`;
    }
    return scene.subtitle ?? '';
  });
  const nativeScripts = scenes.map(scene => {
    if (!('native_subtitle' in scene)) return '';
    if ('speaker' in scene) {
      return `${(scene.speaker && scene.speaker + ': ') || ''}${
        scene.native_subtitle
      }`;
    }
    return (scene as BilingualStoryClosedScene).native_subtitle;
  }) as string[];

  return (
    <div className={styles.container}>
      <div className={styles.right}>
        <PreviewBox
          preview={loading ? '' : video}
          type="Video"
          ratio={formatAspectRatio(size)}
          onLoad={onVideoLoaded}
          loading={loading}
        />
      </div>
      <div className={styles.left} style={{width: detailWidth}}>
        <div className={styles['left-scroll']}>
          <div className={styles.box}>
            <div className={styles['title-box']}>
              <p
                className={classNames(styles.title, {
                  'rtl-element-global': isRtl(languages, language),
                })}
              >
                {trimNewline(
                  getTitleOrDefault(title, language ?? 'en-US'),
                  language ?? 'en-US'
                )}
              </p>
              <CopyIcon className={styles.icon} onClick={() => onCopy(title)} />
            </div>
            <ul className={styles.infos}>
              <li>{`${scenesNumber} Scene${scenesNumber > 1 ? 's' : ''}`}</li>
              <li>
                {size[0]}x{size[1]}
              </li>
              {duration && <li>{duration.toFixed(1)} s</li>}
            </ul>
          </div>
          <div className={styles.box}>
            <div className={styles['title-box']}>
              <p className={styles.title}>Summary</p>
              <CopyIcon
                className={styles.icon}
                onClick={() => onCopy(description)}
              />
            </div>
            <p
              className={classNames(styles.content, {
                'rtl-element-global':
                  language && nativeLanguage
                    ? isRtl(getNativeLanguages(language), nativeLanguage)
                    : isRtl(languages, language),
              })}
            >
              {description}
            </p>
          </div>
          <div className={styles.box}>
            <div className={styles['title-box']}>
              <p className={styles.title}>Hashtags</p>
              <CopyIcon
                className={styles.icon}
                onClick={() => onCopy(hashTagStr)}
              />
            </div>
            <p
              className={classNames(styles.content, {
                'rtl-element-global':
                  language && nativeLanguage
                    ? isRtl(getNativeLanguages(language), nativeLanguage)
                    : isRtl(languages, language),
              })}
            >
              {hashTagStr}
            </p>
          </div>
          <div className={styles.box}>
            <div className={styles['title-box']}>
              <p className={styles.title}>Script</p>
              <CopyIcon
                className={styles.icon}
                onClick={() => onCopy(getScriptCopyContent(scenes))}
              />
            </div>
            <ScriptPreviewer
              isRtl={isRtl(languages, language)}
              scripts={scripts}
              isNativeRtl={
                language && nativeLanguage
                  ? isRtl(getNativeLanguages(language), nativeLanguage)
                  : undefined
              }
              nativeScripts={nativeScripts}
            />
          </div>
          {prompt && (
            <div className={styles.box}>
              <div className={styles['title-box']}>
                <p className={styles.title}>Prompt</p>
                <CopyIcon
                  className={styles.icon}
                  onClick={() => onCopy(prompt)}
                />
              </div>
              <p
                className={classNames(styles.content, {
                  'rtl-element-global': isRtl(languages, language),
                })}
              >
                {prompt}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
